import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { AuthGuard } from '@src/core/guards/auth.guard'
import { UserKeycloakRoles } from '@src/core/keycloak-role'
import { AuthComponent } from '@src/layouts/mobile/auth/auth.component'
import { LayoutsModule } from '@src/layouts/mobile/layouts.module'

export const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'accueil', pathMatch: 'full' },
      {
        path: 'accueil',
        loadChildren: () => import('./views/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'depots',
        data: { roles: [UserKeycloakRoles.STOCK_OPERATOR] },
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/warehouse/warehouse.module').then((m) => m.WarehouseModule),
      },
      {
        path: 'operations',
        data: { roles: [UserKeycloakRoles.STOCK_OPERATOR] },
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/operations/operations.module').then((m) => m.OperationsModule),
      },
      // {
      //   path: 'transporteurs',
      //   loadChildren: () => import('./views/dashboard/transporter/transporter.module').then((m) => m.TransporterModule),
      //   data: { roles: [UserKeycloakRoles.STOCK_TRANSPORTER] },
      //   canActivate: [AuthGuard],
      // },
      {
        path: 'tableau-de-bord',
        loadChildren: () => import('./views/dashboard/dashboard.module').then((m) => m.DashboardModule),
        data: { roles: [UserKeycloakRoles.STOCK_OPERATOR] },
        canActivate: [AuthGuard],
      },
    ],
  },
  { path: '**', redirectTo: 'accueil' },
]

@NgModule({
  imports: [LayoutsModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutesModule {}
